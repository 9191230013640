// 引入请求方法
import { fun_GetAction } from "@/api";
// 默认导出
export default {
  // data
  data: () => {
    return {
      platformName: null, // 交易中心名称
      // 列表数据
      data: [],
      // 表头数据
      tableColumns: [
        {
          label: "招标项目名称",
          prop: "projectName"
        },
        {
          label: "招标项目编号",
          prop: "projectNo"
        },
        {
          label: "标段（包）名称",
          prop: "bidSectionName"
        },
        {
          label: "标段（包）编号",
          prop: "bidSection"
        },
        {
          label: "开标开始时间",
          prop: "meetingStartDate"
        },
        {
          label: "开标进度",
          width: "90",
          slotName: "stateName"
        },
        {
          label: "操作",
          slotName: "op"
        }
      ],
      token: null
    };
  },
  // 模板编译完成
  mounted() {
    // 一览
    this.fun_GetList();
    // 获取token
    this.token = sessionStorage.getItem("token");
  },
  // 自定义方法
  methods: {
    /**
     * @author 滕威
     * @description 一览
     */
    async fun_GetList() {
      // 获取编号
      const tradeCenterNo = this.$route.query.tradeCenterNo;
      // 获取交易中心名称
      this.platformName = this.$route.query.platformName;
      // 请求获取res
      let res = await fun_GetAction(
        "/bidOpenInfo/getTrandeProject?tradeCenterNo=" + tradeCenterNo
      );
      // 解构res.data
      const { result, data, msg } = res.data;
      // 判断请求是否成功
      if (result) {
        // 回显列表
        this.data = data;
      } else {
        // 请求失败，提示错误信息
        if (msg) this.$message.error(msg);
      }
    },
    /**
     * @author 滕威
     * @description 进入详情
     */
    fun_GetInfo(data) {
      // 获取编号
      const tradeCenterNo = this.$route.query.tradeCenterNo;
      // 进入详情页
      this.$router.push({
        path: "/tradingCenterDetail",
        query: { id: data.id, platformName: this.platformName,flag:"1",tradeCenterNo:tradeCenterNo }
      });
    },
    /**
     * @author 滕威
     * @description 返回
     */
    fun_GoBack() {
      // 返回上一页
      this.$router.back();
    }
  }
};
